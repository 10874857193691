import { Injectable } from '@angular/core';
import { DropdownOption } from 'src/app/common/dropdown-option';
import { ClientTypeList, DropdownNames, ClientDocumentTypeList } from '../../common/global';
import { CountryService } from '../http/country.service';
import { AUXILIARY } from 'src/app/common/endpoints';
import { ProvinceService } from '../http/province.service';
import { Country } from 'src/app/models/country';
import { Province } from 'src/app/models/province';
import { Town } from 'src/app/models/town';
import { TownService } from '../http/town.service';

@Injectable({
    providedIn: 'root'
})
export class DropdownService {

    clientTypeList: DropdownOption[] = [];
    clientDocumentTypeList: DropdownOption[] = [];
    countryList: Country[] = [];
    provinceList: Province[] = [];
    townList: Town[] = [];

    constructor(private _countryService: CountryService,
      private _provinceService: ProvinceService,
      private _townService: TownService
    ) { }

    initList(dropdowns: string[], dropdownTranslations: any) {
        if (dropdowns.includes(DropdownNames.ClientTypeList)) {
            const sectionList = dropdownTranslations.CLIENT_TYPE_LIST as any[];
            const optionList = ClientTypeList.map(t =>
                new DropdownOption({ value: t.value, display: sectionList[t.description] }));
            this.clientTypeList = this.getDropdown(optionList);
        }

        if (dropdowns.includes(DropdownNames.ClientDocumentTypeList)) {
            const sectionList = dropdownTranslations.CLIENT_DOCUMENT_TYPE_LIST as any[];
            const optionList = ClientDocumentTypeList.map(t =>
                new DropdownOption({ value: t.value, display: sectionList[t.description] }));
            this.clientDocumentTypeList = this.getDropdown(optionList);
        }
    }

    getDropdown(list: DropdownOption[], defaultValue?: DropdownOption) {
        const arr = [];
        if (defaultValue) { arr.push(defaultValue); }
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < list.length; index++) {
            const option = list[index];
            arr.push(option);
        }
        return arr;
  }

  async loadCountryList(): Promise<Country[]> {
    try {
      const list: Country[] = await this._countryService.getList(AUXILIARY.GET_LIST_COUNTRY).toPromise();
      this.countryList = list;
      return list;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async loadProvinceList(): Promise<Province[]> {
    try {
      const list: Province[] = await this._provinceService.getList(AUXILIARY.GET_LIST_PROVINCE).toPromise();
      this.provinceList = list;
      return list;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async loadTownList(): Promise<Town[]> {
    try {
      const list: Town[] = await this._townService.getList(AUXILIARY.GET_LIST_TOWN).toPromise();
      this.townList = list;
      return list;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
}


