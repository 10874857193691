import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { AuthModel } from '../../models/auth-model';
import { User } from '../../models/user';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ApiService<AuthModel> {

  userData = { id: '', name: '', isAdmin: false };

  // user service with common methods
  constructor(httpClient: HttpClient) {
    super(httpClient);
    if (!this.userData.id) {
      this.setLoggedUser();
    }
  }

  setLoggedUser() {
    if (sessionStorage.a3f24f4a11fd0135627ddd8ab9f40cbe) {
      const data = jwt_decode(sessionStorage.a3f24f4a11fd0135627ddd8ab9f40cbe);
      if (data) {
        this.userData.id = data.id;
        this.userData.name = data.name;
        this.userData.isAdmin = data.isAdmin;
      }
    }
  }

  logOut() {
    this.userData = { id: '', name: '', isAdmin: true };
    sessionStorage.clear();
    localStorage.clear();
  }
}

