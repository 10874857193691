<router-outlet>
    <ng-container *ngIf="config">
        <ngx-ui-loader [fgsColor]="config.fgsColor" [fgsPosition]="config.fgsPosition" [fgsSize]="config.fgsSize"
            [fgsType]="config.fgsType" [bgsColor]="config.bgsColor" [bgsOpacity]="config.bgsOpacity"
            [bgsPosition]="config.bgsPosition" [bgsSize]="config.bgsSize" [bgsType]="config.bgsType" [gap]="config.gap"
            [overlayColor]="config.overlayColor" [pbColor]="config.pbColor" [pbDirection]="config.pbDirection"
            [pbThickness]="config.pbThickness" [hasProgressBar]="config.hasProgressBar" [text]="config.text"
            [textColor]="config.textColor" [textPosition]="config.textPosition">
        </ngx-ui-loader>
    </ng-container>
</router-outlet>