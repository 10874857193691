import { Component } from '@angular/core';
import { NgxUiLoaderConfig } from 'ngx-ui-loader';
import { ngxUiLoaderConfig } from './common/global';
import { LanguageService } from './services/ui/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  config: NgxUiLoaderConfig;

  constructor(public languageService: LanguageService) {
    this.config = ngxUiLoaderConfig;
    this.languageService.setLanguageSettings();
  }
}
