import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Town } from 'src/app/models/town';

@Injectable({
  providedIn: 'root'
})
export class TownService extends ApiService<Town>{
  constructor(httpClient: HttpClient) { super(httpClient); }
}
