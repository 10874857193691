export const CLIENTS = {
    GET_LIST: 'clients',
    GET_PAGINATION_LIST: 'clients/pagination',
    GET_SINGLE: 'clients/',
    CREATE: 'clients',
    UPDATE: 'clients',
    DELETE: 'clients',
};

export const CONNECTIONS = {
    GET_LIST: 'connections',
    GET_SINGLE: 'connections/',
    CREATE: 'connections',
    UPDATE: 'connections',
    DELETE: 'connections',
    GET_CONNECTIONS_BY_CLIENTS_ID: 'connections/getbyclientid/',
    SAVE_LIST: 'connections/savelist',
};

export const USERS = {
    GET_LIST: 'users',
    GET_SINGLE: 'users/',
    GET_DSA_USERS: 'users/dsausers',
    CREATE: 'users',
    UPDATE: 'users',
    DELETE: 'users',
    UPLOAD: 'uploadProfilePicture',
    CHECK_ADMIN_USERS: 'users/checkadminusers/'
};

export const AUTH = {
    LOGIN: 'auth'
};

export const CLINICS = {
    GET_PAGINATION_LIST: 'clinics/pagination',
    GET_SINGLE: 'clinics/',
    CREATE: 'clinics',
    UPDATE: 'clinics',
    DELETE: 'clinics',
    GET_CLINICS_BY_CLIENTS_ID_PAGINATION: 'clinics/getbyclientidpagination/',
    GET_CLINICS_BY_CLIENTS_ID: 'clinics/getbyclientid/',
};

export const LABS = {
    GET_PAGINATION_LIST: 'labs/pagination',
    GET_SINGLE: 'labs/',
    CREATE: 'labs',
    UPDATE: 'labs',
    DELETE: 'labs',
    GET_LABS_PAGINATION_BY_CLIENTS_ID: 'labs/getbyclientid/',
    GET_LABS_BY_CLIENTS_ID: 'labs/getlabsbyclientid/'
};

// api keys paths
export const LICENSEKEYS = {
    GET_LIST: 'licensekeys',
    GET_SINGLE: 'licensekeys/',
    CREATE: 'licensekeys',
    UPDATE: 'licensekeys',
    DELETE: 'licensekeys',
    GET_LICENSEKEYS_BY_CLIENTS_ID: 'licensekeys/getbyclientid/',
    CHECK_ALREADY_USED_DATA: 'licensekeys/checkusedapikeysforclients/'
};

// dsa association paths
export const DSA_ASSOCIATIONS = {
    SAVE_LIST: 'dsaassociations/savelist',
    GET_LIST_BY_CLIENT_ID: 'dsaassociations/getbyclientid/',
    UPDATE: 'dsaassociations',
    DELETE: 'dsaassociations'
};

// Auxiliary path
export const AUXILIARY = {
  GET_PAGINATION_COUNTRY_LIST: 'auxiliary/country/pagination',
  CREATE_COUNTRY: 'auxiliary/country',
  UPDATE_COUNTRY: 'auxiliary/country',
  DELETE_COUNTRY: 'auxiliary/country',
  GET_LIST_COUNTRY: 'auxiliary/country/list',
  UPDATE_STATUS_COUNTRY: 'auxiliary/country/updatestatus',

  GET_PAGINATION_PROVINCE_LIST: 'auxiliary/province/pagination',
  CREATE_PROVINCE: 'auxiliary/province',
  UPDATE_PROVINCE: 'auxiliary/province',
  DELETE_PROVINCE: 'auxiliary/province',
  GET_LIST_PROVINCE: 'auxiliary/province/list',
  UPDATE_STATUS_PROVINCE: 'auxiliary/province/updatestatus',

  GET_PAGINATION_TOWN_LIST: 'auxiliary/town/pagination',
  CREATE_TOWN: 'auxiliary/town',
  UPDATE_TOWN: 'auxiliary/town',
  DELETE_TOWN: 'auxiliary/town',
  GET_LIST_TOWN: 'auxiliary/town/list',
  UPDATE_STATUS_TOWN: 'auxiliary/town/updatestatus',
};



