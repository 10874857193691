import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Province } from 'src/app/models/province';

@Injectable({
  providedIn: 'root'
})
export class ProvinceService extends ApiService<Province>{
  constructor(httpClient: HttpClient) { super(httpClient); }
}
