import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  constructor() { }

  setDefaultPicture(event: any) {
    event.target.src = 'assets/images/image-not-found.png';
  }
}
