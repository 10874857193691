import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Country } from 'src/app/models/country';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends ApiService<Country>{
  constructor(httpClient: HttpClient) { super(httpClient); }


}
