import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = sessionStorage.getItem('a3f24f4a11fd0135627ddd8ab9f40cbe');
        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', token) });
        }
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) { }
                return event;
            }),
            catchError(error => {
                console.clear();
                console.log('Error:: dentbox : ' + error.statusText);
                return throwError(error);
            }));
    }
}
