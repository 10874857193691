import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    // tslint:disable-next-line: variable-name
    constructor(private _route: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (sessionStorage.a3f24f4a11fd0135627ddd8ab9f40cbe && sessionStorage.a3f24f4a11fd0135627ddd8ab9f40cbe.length > 10) {
            return true;
        } else {
            this._route.navigate(['/']);
            return false;
        }
    }
}
