import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './layouts/auth/auth.component';
import { MasterComponent } from './layouts/master/master.component';
import { AuthGuard } from './common/auth-guard';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    component: MasterComponent,
    children: [
      {
        path: 'clients',
        loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'clinics',
        loadChildren: () => import('./modules/clinic/clinic.module').then(m => m.ClinicModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'labs',
        loadChildren: () => import('./modules/lab/lab.module').then(m => m.LabModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'auxiliary',
        loadChildren: () => import('./modules/auxiliary/auxiliary.module').then(m => m.AuxiliaryModule),
        canActivate: [AuthGuard]
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
