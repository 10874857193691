import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { DropdownService } from '../data/dropdown.service';
import { DropdownNames } from 'src/app/common/global';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    translations: any[];

    constructor(private _translationService: TranslateService,
        private _dropdownService: DropdownService) {
        this._translationService.onLangChange.subscribe(
            (trans: { lang: string, translations: any }) => {
                this.translations = trans.translations;
                this.initDropdowns(this.translations);
            });
    }

    setLanguageSettings() {
        this._translationService.addLangs(['en', 'es']);
        this._translationService.setDefaultLang('en');
        if (sessionStorage.lang) {
            this._translationService.use(sessionStorage.lang);
        } else {
            const browserLang = this._translationService.getBrowserLang();
            this._translationService.use(browserLang.match(/en|es/) ? browserLang : 'en');
        }
    }

    async getTranslation(key: string) {
        return await this._translationService
            .get(key).pipe(map(trans => trans as string)).toPromise();
    }

    initDropdowns(translations: any) {
        this._dropdownService.initList(
            [DropdownNames.ClientTypeList, DropdownNames.ClientDocumentTypeList],
            translations.DROPDOWNS);
    }
}
