import { NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader } from '@ngx-translate/core';

export const uiSwitchConfig = {
    size: 'small',
    color: 'rgb(0, 189, 99)',
    switchColor: '#80FFA2',
    defaultBgColor: '#00ACFF',
    defaultBoColor: '#476EFF',
    checkedLabel: 'on',
    uncheckedLabel: 'off'
};

export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: 'linear-gradient(120deg, #00e4d0, #5983e8)',
    bgsOpacity: 0.5,
    bgsPosition: POSITION.centerCenter,
    bgsSize: 60,
    bgsType: SPINNER.squareLoader,
    fgsColor: '#ffff',
    fgsPosition: POSITION.centerCenter,
    fgsSize: 35,
    fgsType: SPINNER.squareLoader,
    pbColor: 'linear-gradient(120deg, #00e4d0, #5983e8)',
    pbDirection: PB_DIRECTION.leftToRight,
    pbThickness: 5,
    textColor: '#FFFFFF',
    textPosition: POSITION.centerCenter
};

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

export const translateLoader = {
    loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
};

export const toastrConfig = {
    timeOut: 3000,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true
};

export const CustomTranslations = {
    en: { select: '--Select--', },
    es: { select: '--Seleccionar--', }
};

export const clientType = [
    { value: 'clinic', description: 'Dental Clinic' },
    { value: 'lab', description: 'Prosthetic Laboratory' }
];

export const documentType = [
    { value: 'nif', description: 'NIF' },
    { value: 'nie', description: 'NIE' },
    { value: 'cif', description: 'CIF' },
    { value: 'passport', description: 'Passport' }
];

export const province = [
    { value: 'Andalucía (Andalusia)', description: 'Andalucía (Andalusia)' },
    { value: 'Aragon', description: 'Aragon' },
    { value: 'Asturias', description: 'Asturias' },
    { value: 'Basque Country', description: 'Basque Country' },
    { value: 'Cantabria', description: 'Cantabria' },
    { value: 'Castilla la Mancha', description: 'Castilla la Mancha' },
    { value: 'Castilla y León (Castille & Leon)', description: 'Castilla y León (Castille & Leon)' },
    { value: 'Catalunya (Catalonia)', description: 'Catalunya (Catalonia)' },
    { value: 'Extremadura', description: 'Extremadura' },
    { value: 'Galicia', description: 'Galicia' },
    { value: 'Islas Baleares (Balearic Islands)', description: 'Islas Baleares (Balearic Islands)' },
    { value: 'Islas Canarias (Canary Islands)', description: 'Islas Canarias (Canary Islands)' },
    { value: 'La Rioja', description: 'La Rioja' },
    { value: 'Madrid', description: 'Madrid' },
    { value: 'Murcia', description: 'Murcia' },
    { value: 'Navarra (Navarre)', description: 'Navarra (Navarre)' },
    { value: 'Valenciana (Valencia)', description: 'Valenciana (Valencia)' }
];

export const DropdownNames = {
    ClientTypeList: 'client-type-list',
    ClientDocumentTypeList: 'client-document-type-list'
};

export const ClientTypeList = [
    { value: 'clinic', description: 'CLINIC' },
    { value: 'lab', description: 'LAB' }
];

export const ClientDocumentTypeList = [
    { value: 'nif', description: 'NIF' },
    { value: 'nie', description: 'NIE' },
    { value: 'cif', description: 'CIF' },
    { value: 'passport', description: 'PASSPORT' }
];

export const FileServePath = {
    BackOffice: 'backoffice'
};

